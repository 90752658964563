import { Injectable } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { gainsight, matomoSettings } from '@monsido/core/constants/general.constant';
import { MatomoService } from '@client/app/modules/tracking/services/matomo.service';
import { GainsightService } from '@client/app/modules/tracking/services/gainsight.service';
import { SessionService } from '@monsido/core/session/session.service';

declare global {
    interface Window {
        aptrinsic: any;
    }
}

@Injectable({
    providedIn: 'root',
})

export class TrackingRunService {
    constructor (
        private monEventService: MonEventService,
        private matomoService: MatomoService,
        private gainsightService: GainsightService,
        private sessionService: SessionService,
    ) {}

    initializeApp () {
        if (matomoSettings?.matomoUrl) {
            this.matomoService.setTrackingInfo(matomoSettings);
        }
        this.monEventService.addListener('trackPageView', (url, title, ref, type, params) => {
            this.matomoService.trackPageView(url, title, ref, type, params);
        });
        this.monEventService.addListener('trackEvent', (url, type, ref)=> {
            this.matomoService.trackEvent(url, type, ref);
            this.gainsightService.trackEvent(url, type, ref);
        });
        if (gainsight?.apiKey) {
            this.gainsightService.setTrackingInfo(gainsight);
        }
        this.monEventService.addListener('finishCustomer',() => {
            if (!this.sessionService.isSudo) {
                window.aptrinsic('identify', {
                    // User Info
                    'id': this.sessionService.me?.id,
                    'signUpDate': this.sessionService.me?.created_at ? new Date(this.sessionService.me.created_at).getTime() : undefined,
                    'plan': this.sessionService.customer?.plan_type,
                    'GlobalAdmin': this.sessionService.agreement?.admin,
                    'isTrialuser': this.sessionService.customer?.plan_type === 'demo',
                    'isAcquian': this.sessionService.me?.email?.endsWith('@acquia.com'),
                },
                {
                    // Account Info
                    'id': this.sessionService.customer?.id,
                    'name': this.sessionService.customer?.name,
                    'Program': this.sessionService.customer?.plan_type,
                    'DoNotShowSurveys': false,
                });
            }
        });
    }
}
